module.exports = {
    siteUrl: `https://clarsynt.com`, // Site domain. Do not include a trailing slash!

    postsPerPage: 12, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

    siteTitleMeta: `Business Consulting Services | Clarsynt | AI Strategy Advisors`, // This allows an alternative site title for meta data for pages.
    siteDescriptionMeta: `Business consulting services with a view toward an AI enabled future. We implement AI strategies and increase operational efficiency for your business.`, // This allows an alternative site description for meta data for pages.

    shareImageWidth: 1000, // Change to the width of your default share image
    shareImageHeight: 523, // Change to the height of your default share image

    shortTitle: `Clarsynt`, // Used for App manifest e.g. Mobile Home Screen
    siteIcon: `favicon.png`, // Logo in /static dir used for SEO, RSS, and App manifest
    backgroundColor: `#3C4B56`, // Used for Offline Manifest
    themeColor: `#F7F8F8`, // Used for Offline Manifest
};
